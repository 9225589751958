<template>
    <div class="root-wrap">
        <div class="header-wrap">
            <div class="block">
                <div class="order-wrap fx-row">
                    <div class="order-logo">
                        <img class="order-logo_img" :src="orderInfo.logo" />
                    </div>
                    <div class="fx-fill order-info">
                        <div class="fx-row fx-between">
                            <div>{{ orderInfo.movieName }}</div>
                            <div>{{ orderInfo.quantity }}张</div>
                        </div>
                        <div class="fc-tips">{{ orderInfo.seats }}</div>
                        <div class="fc-tips">{{ orderInfo.cinemaName }}</div>
                        <div class="fx-fill fx-end">
                            <span style="color: #000;font-weight: 700;">实付</span>
                            <span class="fc-status">￥{{orderInfo.amount}}</span>
                        </div>
                    </div>
                </div>
                <!-- <div v-if="showRefund" class="commission">
                    <div class="commission-title">退款手续费</div>
                    <div class="commission-value">￥{{serviceAmount | formatMoney}}</div>
                </div>
                <div v-else class="refund-status">
                    <div>退款详情</div>
                    <van-steps :active="active" direction="vertical">
                        <van-step>
                            <div>{{applyRefund.statusText}}</div>
                            <div>{{applyRefund.addTime}}</div>
                        </van-step>
                    </van-steps>
                </div> -->
            </div>
        </div>
        <div class="body-wrap">
            <div v-if="showRefund" class="block">
                <div class="remark-title">退款说明:</div>
                <div v-html="note" >
                </div>
            </div>
            <div v-if="showRefund" class="block">
                <div v-html="refundAmountNote" class="fc-error" style="font-size: 15px;font-weight: 700;"></div>
            </div>
        </div>
        <div v-if="showRefund" class="footer-wrap">
            <div></div>
            <van-button round size="small" type="primary" @click="confirm" style="padding-left: 20px;padding-right: 20px;">申请退款</van-button>
        </div>
    </div>
</template>

<script>
import { getApplyRefund, saveApplyRefund, getOrder } from '@api/order-request'
export default {
    data() {
        return {
            active: 0,
            orderId: this.$route.query.orderId,
            orderInfo: {},
            serviceAmount: 0,
            applyRefund: {},
            note: '',
            refundAmountNote: '',
            refundAmount: 0,
        }
    },
    computed: {
        showRefund() {
            return true;
        }
    },
    created() {
        this.initPage();
    },
    methods: {
        confirm() {
            this.saveApplyRefund();
        },
        getApplyRefund() {
            getApplyRefund(this.orderId).then((res) => {
                let { serviceAmount, applyRefund, note, refundAmountNote, refundAmount } = res;
                this.serviceAmount = serviceAmount || 0;
                this.applyRefund = applyRefund || {};
                this.note = note || '';
                this.refundAmountNote = refundAmountNote;
                this.refundAmount = refundAmount;
            })
        },
        saveApplyRefund() {
            this.$dialog.confirm({
                title: '退款提醒',
                // message: `确认退款将收取您手续费<span class="fc-active">￥${this.serviceAmount}</span>是否继续?`
                message: `此单仅退<span class="fc-active">￥${this.refundAmount}</span></br>是否继续`
            }).then(() => {
                let params = {
                    orderId: this.orderId,
                }
                saveApplyRefund(params).then((res) => {
                    this.$toast('申请成功');
                    this.initPage();
                }).catch(({ errorMsg }) => {
                    this.$toast(errorMsg);
                })
            })

        },
        getOrder() {
            getOrder(this.orderId).then((res) => {
                this.orderInfo = res;
            })
        },
        initPage() {
            this.getOrder();
            this.getApplyRefund();
        }
    },
    filters: {
        formatMoney(val) {
            return Number(val).toFixed(2)
        }
    }
}
</script>

<style lang="scss" scoped>
.header-wrap,
.body-wrap {
	padding: 8px 10px;
}

.body-wrap {
	flex: 1;
}

.block {
	background-color: #fff;
	border-radius: 4px;
	margin-bottom: 8px;
	padding: 10px;
}

.order-wrap {
	padding-bottom: 10px;
	// border-bottom: 1px solid $border-color;
	.order-logo_img {
		border-radius: 4px;
		height: 110px;
		width: 80px;
	}

	.order-info {
		display: flex;
		flex-direction: column;
		margin-left: 10px;
		> div {
			margin-bottom: 4px;

			&.fx-end {
				justify-content: flex-end;
			}
		}
	}
}

.commission {
	// border-bottom:1px solid $border-color;
	padding-top: 10px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	.commission-title {
		font-size: 14px;
		font-weight: 700;
	}

	.commission-value {
		font-size: 16px;
	}
}

.refund-status {
	padding-top: 10px;
}

.footer-wrap {
	background-color: #fff;
	padding: 16px 16px;
	display: flex;
	flex-direction: flex-row;
	justify-content: space-between;
}

.remark-title {
	font-size: 14px;
	font-weight: 700;
}
</style>
